import React, { useEffect, useState } from 'react'
import { getActivityLogs } from '../../utilities/tokenUtils'
import ActivityLogItem from './ActivityLogItem'
import { LoadMoreButton, NoTokens } from './style'

const ActivityLog = ({ tokens }) => {
  const [showMore, setShowMore] = useState(false)
  const [activityLogs, setActivityLogs] = useState([])
  const [logsToDisplay, setLogsToDisplay] = useState([])

  useEffect(() => {
    if (!tokens?.length) return

    const formattedActivityLogs = getActivityLogs(tokens)

    setActivityLogs(formattedActivityLogs)
    setLogsToDisplay(formattedActivityLogs.slice(0, 5))
    setShowMore(formattedActivityLogs.length > 5)

    // eslint-disable-next-line
  }, [tokens])

  const loadMoreLogs = () => {
    setLogsToDisplay(showMore ? activityLogs : activityLogs.slice(0, 5))
    setShowMore(showMore => !showMore)
  }

  if (!activityLogs?.length) return <NoTokens>You don’t have any token history.</NoTokens>

  return (
    <>
      {logsToDisplay.map((log, index) => {
        const { date, event, description, count } = log
        return (
          <ActivityLogItem
            key={'log' + index}
            date={date}
            count={count}
            label={event}
            description={description}
          />
        )
      })}
      {activityLogs.length > 5 && (
        <LoadMoreButton data-testid='btn-load-more' onClick={loadMoreLogs}>
          {showMore ? 'Load More' : 'Load Less'}
        </LoadMoreButton>
      )}
    </>
  )
}

ActivityLog.displayName = 'ActivityLog'
export default ActivityLog
