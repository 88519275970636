import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../ContextProvider/ContextProvider'
import { OverviewContainer } from './style'
import SectionDetails from './SectionDetails'
import api from '../../api'
import { getCoursesWithConfigId } from '../../utilities/course'

const TokenAndCourseInfo = ({
  tokens
}) => {
  const [outlierCourses, setOutlierCourses] = useState([])
  const { courses } = useContext(AppContext) || {}

  const getOutlierCourses = async () => {
    const courses = await api.getActiveCourses()
    const filteredCourses = getCoursesWithConfigId(courses)
    setOutlierCourses(filteredCourses)
  }

  useEffect(() => {
    getOutlierCourses()

    // eslint-disable-next-line
  }, [])
  const tokensTitle = tokens?.length === 1 ? 'Token' : 'Tokens'

  return (
    <OverviewContainer>
      <SectionDetails
        sectionItemCount={tokens?.length || 0}
        totalCount={0}
        title={tokensTitle}
      />
      <SectionDetails
        sectionItemCount={courses?.length || 0}
        totalCount={outlierCourses.length}
        title='Courses'
      />
    </OverviewContainer>
  )
}

TokenAndCourseInfo.propTypes = {
  tokens: PropTypes.arrayOf(PropTypes.object)
}

TokenAndCourseInfo.displayName = 'TokenAndCourseInfo'

export default TokenAndCourseInfo
