import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Separator } from '../../OnboardingForm/style'
import CloseIcon from '../../../assets/icons/modal-close.svg'
import { GlobalStyle } from '../style'
import config from '../../../config'

const NonRefundableModal = ({ show, setShowModal }) => {
  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <div>
        <p>Where are my other purchases?</p>
        <img
          data-testid='btn-close-modal'
          src={CloseIcon}
          alt='Close Icon'
          onClick={() => setShowModal(false)}
        />
      </div>
      <Separator margin='24px 0' />
      <p className='no-bottom-padding'>
        Only purchases that are eligible for a refund will display here.
        Certain purchases are not eligible for a refund, including:
      </p>
      <ul>
        <li>Any purchases whose refund window has elapsed.</li>
        <li>Tokens used for courses that you stayed in past the Drop deadline.</li>
        <li>Certificates that have been partially used.</li>
        <li>
          Certificates and tokens granted as part of a scholarship or as part of
          a partnership with your school or workplace.
        </li>
      </ul>
      <p>
        See our{' '}
        <a
          href={config.refundPolicyLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          Refund Policy
        </a> for more information. If you'd like to talk to a team member,{' '}
        please email <a href='mailto:success@outlier.org'>success@outlier.org</a>.
      </p>
    </Modal>
  )
}

NonRefundableModal.displayName = 'NonRefundableModal'
export default NonRefundableModal
