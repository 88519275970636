import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import { getActiveTokens } from '../../utilities/tokenUtils'
import {
  AMAZON_REFUND_PATH,
  CONTENT,
  GGU_REFUND_URL
} from '../../Constants/accountArea'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import {
  Snackbar,
  RefundSection,
  TokensContainer,
  SectionHeader,
  NoTokens
} from './style'
import TokenDetails from './TokenDetails'
import CloseIcon from '../../assets/icons/modal-close.svg'
import RefundSuccess from '../../assets/icons/correct.svg'
import ActivityLog from './ActivityLog'
import TokenAndCourseInfo from './TokenAndCourseInfo'
import {
  AMAZON_RELATIONSHIP,
  GGU_RELATIONSHIP
} from '../../Constants/relationships'
import config from '../../config'

const Tokens = ({
  tokens,
  setTokens,
  showSnackbar,
  setShowSnackbar
}) => {
  const history = useHistory()
  const [activeTokens, setActivetokens] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [tokenType, setTokenType] = useState({
    hasGGUToken: false,
    hasAmazonToken: false,
    hasOtherToken: false
  })
  const snackbarFadeOutTime = 10
  const showTokenActivityLog = config.showTokenActivityLog()

  const showConfirmationToast = () => {
    setShowSnackbar('show')
    setTimeout(() => {
      setShowSnackbar('')
    }, snackbarFadeOutTime * 1000)
  }

  useEffect(() => {
    if (!showSnackbar) return

    showConfirmationToast()
  })

  useEffect(() => {
    const getTokens = async () => {
      const studentTokens = await api.getAllTokens({ activityLog: showTokenActivityLog })
      setIsLoading(false)

      if (!Array.isArray(studentTokens)) {
        const { message } = studentTokens || {}
        console.error('Error when fetching tokens: ', message)
        setTokens([])
        return
      }

      let [hasGGUToken, hasAmazonToken, hasOtherToken] = [false, false, false]
      const activeTokens = getActiveTokens(studentTokens)
      const filteredActiveTokens = activeTokens.filter((token) => {
        const relationShipName = token?.relationship[0]?.fields?.relationshipName
        const isGGU = relationShipName === GGU_RELATIONSHIP
        const isAmazon = relationShipName === AMAZON_RELATIONSHIP
        if (isGGU) {
          hasGGUToken = true
          return false
        } else if (isAmazon) {
          hasAmazonToken = true
          return false
        } else {
          hasOtherToken = true
          return true
        }
      })
      setActivetokens(filteredActiveTokens)
      setTokenType({
        hasGGUToken,
        hasAmazonToken,
        hasOtherToken
      })
      setTokens(studentTokens)
    }

    getTokens()
  // eslint-disable-next-line
  }, [])

  const openRefund = () => {
    history.push(`/${CONTENT.REFUND_TOKEN}`)
  }

  if (isLoading) return <LoadingSpinner />

  const numberOfTokens = activeTokens.length

  const showRefundSection = Object.values(tokenType).some((typeVal) => typeVal)

  const navigateToGGURefundURL = () => {
    window.open(GGU_REFUND_URL, '_blank')
  }

  const navigateToAmazonRefundRequest = () => {
    history.push(AMAZON_REFUND_PATH)
  }

  return (
    <TokensContainer data-testid='tokens-page'>
      <TokenAndCourseInfo tokens={activeTokens} />

      <div className='section'>
        <SectionHeader>
          Your Tokens: {numberOfTokens}
        </SectionHeader>
        {numberOfTokens
          ? activeTokens.map(token => {
            const { tokenNumber, tokenExpirationDate, relationship } = token
            return (
              <TokenDetails
                key={tokenNumber}
                expirationDate={tokenExpirationDate}
                relationship={relationship?.length ? relationship[0]?.fields : {}}
              />
            )
          })
          : <NoTokens>You don’t have any tokens.</NoTokens>}
      </div>

      {showTokenActivityLog && (
        <div className='section'>
          <SectionHeader>
          Activity Log
          </SectionHeader>
          {tokens?.length
            ? <ActivityLog tokens={tokens} />
            : <NoTokens>You don’t have any token history.</NoTokens>}
        </div>
      )}

      {showRefundSection && (
        <div className='section'>
          <RefundSection>
            {tokenType.hasGGUToken &&
              <p>
                Start a Degrees+ refund request by viewing the{' '}
                <span onClick={navigateToGGURefundURL}>
                  Golden Gate University Tuition & Fees Policy
                </span>.
              </p>}
            {tokenType.hasAmazonToken &&
              <p>
                Start a return request for an Amazon Career Choice purchase{' '}
                <span onClick={navigateToAmazonRefundRequest}>here</span>.
              </p>}
            {tokenType.hasOtherToken &&
              <p>
                For all other eligible purchases, start your refund request{' '}
                <span data-testid='refund-token' onClick={openRefund}>
                  using this tool
                </span>.
              </p>}
          </RefundSection>
        </div>
      )}

      <Snackbar
        data-testid='refund-complete'
        className={showSnackbar}
      >
        <div>
          <img
            data-testid='refund-success'
            src={RefundSuccess}
            alt='Refund Successful'
          />
          <span>Refund initiated</span>
          <img
            data-testid='btn-close-snackbar'
            src={CloseIcon}
            alt='Close Icon'
            onClick={() => setShowSnackbar('')}
          />
        </div>
        <p>
          We’ve sent a confirmation email with additional information about your return.
        </p>
      </Snackbar>
    </TokensContainer>
  )
}

Tokens.displayName = 'Tokens'
export default Tokens
