import React, { useState } from 'react'
import {
  LogItem
} from './style'
import Collapse from 'react-bootstrap/Collapse'
import CollapseIcon from '../../assets/icons/caret.svg'
import ExpandIcon from '../../assets/icons/caret-inverse.svg'

const ActivityLogItem = ({
  date,
  label,
  description,
  count
}) => {
  const [openLog, setOpenLog] = useState(false)
  const positiveCount = label.toLowerCase().includes('added')

  return (
    <LogItem data-testid='log-item'>
      <div>
        <p className={`count${positiveCount ? '' : ' minus'}`}>
          {`${positiveCount ? '+' : '-'}${count}`}
        </p>
        <p className='label'>{label}</p>
        <p className='dot'>.</p>
        <p className='date'>{date}</p>
        <img
          onClick={() => setOpenLog(openLog => !openLog)}
          data-testid='img-collapse'
          alt='Activity Expand'
          src={openLog ? ExpandIcon : CollapseIcon}
        />
      </div>
      <Collapse in={openLog}>
        <div>
          <p>{description}</p>
        </div>
      </Collapse>
    </LogItem>
  )
}

ActivityLogItem.displayName = 'ActivityLogItem'
export default ActivityLogItem
