import React, { useContext, useState } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import { useLocation, useParams } from 'react-router-dom'
import Navigation from './Navigation'
import PersonalInfoPage from './PersonalInfoPage'
import TranscriptsPage from './TranscriptsPage'
import LoginSecurityPage from './LoginSecurityPage'
import CourseManagement from './CourseManagement'
import VoucherUpload from './VoucherUpload'
import StudyBlocks from './StudyBlocks'
import {
  Container, PageContainer
} from './style'
import {
  CONTENT,
  PERSONAL_INFO,
  LOGIN_SECURITY,
  TRANSCRIPTS,
  TOKENS,
  VOUCHER_UPLOAD,
  STUDY_BLOCKS,
  SUBSCRIPTION,
  COURSE_MANAGEMENT,
  ACCOUNT_PATH
} from '../../Constants/accountArea'
import Tokens from '../Tokens'
import Subscription from './Subscription'
import RefundTokens from '../Tokens/RefundTokens'
import CancellationPage from './Subscription/CancellationPage'
import { getPageNameFromTabName } from '../../utilities/accountArea'

const { REFUND_TOKEN, ACCOUNT } = CONTENT
const { SUBSCRIPTION_CANCELLATION } = ACCOUNT_PATH

function AccountArea () {
  const { studentData = {} } = useContext(AppContext)
  const location = useLocation()
  const pageName = location?.pathname?.split('/')?.[1] || ACCOUNT
  const { tabName = '' } = useParams()
  const activePage = getPageNameFromTabName(tabName)
  const [showSnackbar, setShowSnackbar] = useState('')
  const [tokens, setTokens] = useState([])

  const { instrideId } = studentData

  if (tabName === SUBSCRIPTION_CANCELLATION) {
    return (
      <CancellationPage />
    )
  }

  if (!pageName || pageName === ACCOUNT) {
    return (
      <Container>
        <Navigation activePage={activePage} />

        <PageContainer>
          {activePage === PERSONAL_INFO && (
            <PersonalInfoPage />
          )}

          {activePage === COURSE_MANAGEMENT && (
            <CourseManagement />
          )}

          {activePage === STUDY_BLOCKS && (
            <StudyBlocks />
          )}

          {activePage === TRANSCRIPTS && (
            <TranscriptsPage />
          )}

          {activePage === VOUCHER_UPLOAD && (
            <VoucherUpload />
          )}

          {activePage === TOKENS && !instrideId && (
            <Tokens
              tokens={tokens}
              setTokens={setTokens}
              showSnackbar={showSnackbar}
              setShowSnackbar={setShowSnackbar}
            />
          )}

          {activePage === SUBSCRIPTION && instrideId && (
            <Subscription />
          )}

          {activePage === LOGIN_SECURITY && (
            <LoginSecurityPage />
          )}
        </PageContainer>
      </Container>
    )
  }

  if (pageName === REFUND_TOKEN) {
    return (
      <RefundTokens setShowSnackbar={setShowSnackbar} />
    )
  }
}

AccountArea.displayName = 'AccountArea'
export default AccountArea
