import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Instruction,
  ConfirmTokensList,
  LoadMoreButton,
  ButtonsContainer
} from '../style'
import { Separator } from '../../OnboardingForm/style'
import { getDateStringWithYear } from '../../../utilities/dateTime'
import api from '../../../api'
import { ACCOUNT_PATH, CONTENT } from '../../../Constants/accountArea'
import { TypeText } from '../../ScheduleReview/style'
import { Spinner } from 'react-bootstrap'
import { getRefundExpiryDate } from '../../../utilities/tokenUtils'

const TokenListItem = ({ token }) => {
  const {
    name,
    paidAmount,
    tokens,
    tokenPurchasePrice,
    tokenIssueDate,
    refundEligibilityWindow
  } = token
  const certificateToken = tokens?.[0]
  const {
    tokenIssueDate: certificateIssueDate,
    refundEligibilityWindow: certificateEligibilityWindow
  } = certificateToken || {}
  const refundExpiryDate = certificateToken
    ? getRefundExpiryDate(certificateIssueDate, certificateEligibilityWindow)
    : getRefundExpiryDate(tokenIssueDate, refundEligibilityWindow)
  const formattedDate = refundExpiryDate &&
    getDateStringWithYear(refundExpiryDate.toISOString())

  return (
    <li data-testid='list-token'>
      <div className='label'>
        <p>{name || 'Token'}</p>
        <p>Refund amount: ${name ? paidAmount : tokenPurchasePrice}</p>
        <p className='refund-expiry'>Eligible until {formattedDate}</p>
      </div>
    </li>
  )
}

const ConfirmTokens = ({
  refundableTokens,
  setShowSnackbar
}) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const selectedTokens = refundableTokens.filter(token => !!token.selected)
  const totalAmount = selectedTokens.reduce((previousValue, currentValue) => {
    const { tokens, tokenPurchasePrice, paidAmount } = currentValue
    const isCertificate = !!tokens
    const priceToBeAdded = isCertificate ? paidAmount : tokenPurchasePrice
    return previousValue + Number(priceToBeAdded)
  }, 0)

  const refundTokens = async () => {
    let tokensById = selectedTokens.map(value => {
      const { tokens, id } = value
      if (!tokens) return { id, usedStatus: 'pendingRefund' }

      return tokens.map(token => {
        return {
          id: token.id,
          usedStatus: 'pendingRefund',
          purchaseId: value.purchaseId
        }
      })
    })
    tokensById = Array.isArray(tokensById) ? [].concat.apply([], tokensById) : tokensById
    setIsLoading(true)
    const { message, error } = await api.refundTokens(tokensById)
    setIsLoading(false)

    if (message || error) {
      console.error('Error when refunding tokens', message || error)
      return
    }

    history.push(`/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.TOKENS_PATH}`)
    setShowSnackbar(true)
  }

  return (
    <>
      <Instruction marginBottom='32px' fontWeight='300'>
        Please review your selection. Once confirmed, we'll process
        your refund within 7-10 business days.
      </Instruction>
      <ConfirmTokensList>
        {selectedTokens.map((token, i) => {
          const { id } = token
          return (
            <TokenListItem
              key={id}
              token={token}
            />
          )
        })}
      </ConfirmTokensList>
      <Separator margin='32px 0' />
      <p>
        Refund total: ${totalAmount}
      </p>
      <TypeText>Returning to original payment method</TypeText>
      <ButtonsContainer>
        <Button
          marginTop='0'
          data-testid='btn-confirm'
          onClick={refundTokens}
        >
          {isLoading ? <Spinner animation='border' /> : 'confirm'}
        </Button>
        <LoadMoreButton
          marginLeft='16px'
          marginTop='0'
          width='120px'
          data-testid='btn-cancel'
          onClick={() => {
            history.push(`/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.TOKENS_PATH}`)
          }}
        >
          cancel
        </LoadMoreButton>
      </ButtonsContainer>
    </>
  )
}

ConfirmTokens.displayName = 'ConfirmTokens'
export default ConfirmTokens
