import React from 'react'
import {
  secondsSinceEpoch,
  dateToSecondsSinceEpoch,
  getDateStringWithYear
} from '../../utilities/dateTime'
import {
  DetailsContainer
} from './style'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'

const TokenDetails = ({
  expirationDate,
  relationship
}) => {
  const { relationshipName: tmpRelationshipName, displayName } = relationship
  const relationshipName = displayName || tmpRelationshipName
  const currentDate = secondsSinceEpoch()
  const expirationDateInSeconds = expirationDate &&
    dateToSecondsSinceEpoch(new Date(expirationDate))
  const thirtyDaysInSeconds = 30 * 24 * 60 * 60
  const showWarning = expirationDateInSeconds &&
    (currentDate + thirtyDaysInSeconds) >= expirationDateInSeconds

  return (
    <DetailsContainer>
      <p>Token</p>
      <div className='details'>
        {relationshipName && <p className='relationship'>{relationshipName}</p>}
        <p className='expiration'>
          {showWarning && (
            <WarningIcon data-testid='warning' style={{ marginRight: '6px' }} />
          )}
          Expires {getDateStringWithYear(expirationDate)}
        </p>
      </div>
    </DetailsContainer>
  )
}

TokenDetails.displayName = 'TokenDetails'
export default TokenDetails
