import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Overview } from './style'
import AddTokensButton from '../AddTokens/AddTokensButton'
import { ACCOUNT_PATH, CONTENT } from '../../Constants/accountArea'
import { AppContext } from '../ContextProvider/ContextProvider'

const SectionDetails = ({
  sectionItemCount,
  totalCount,
  title
}) => {
  const { isGGUStudent } = useContext(AppContext)
  const isTokensSection = ['tokens', 'token']
    .includes(title.toLowerCase())

  return (
    <Overview data-testid='token-course-overview'>
      <p className='count'>
        {sectionItemCount}{!isTokensSection && <span>/{totalCount}</span>}
      </p>
      <p className='title'>
        {title}
      </p>
      {isTokensSection
        ? (!isGGUStudent &&
          <AddTokensButton
            availableTokens={sectionItemCount}
            coursesCount={0}
            tokensCount={1}
            backToPageName='Tokens'
            redirectToPath={`/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.TOKENS_PATH}`}
          />
        ) : (
          <p className='link' onClick={() => (window.location.hash = '#/')}>
            visit dashboard
          </p>
        )}
    </Overview>
  )
}

SectionDetails.propTypes = {
  sectionItemCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  title: PropTypes.string.isRequired
}

SectionDetails.displayName = 'SectionDetails'

export default SectionDetails
