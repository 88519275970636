import React, { useState } from 'react'
import {
  Button,
  Instruction,
  CheckBox
} from '../style'
import { Separator } from '../../OnboardingForm/style'
import { getDateStringWithYear } from '../../../utilities/dateTime'
import MissingTokensInfoLink from './MissingTokensInfoLink'
import cloneDeep from 'lodash/cloneDeep'
import utils from '../../../utilities'
import { getRefundExpiryDate } from '../../../utilities/tokenUtils'

const TokenListItem = ({
  index,
  token,
  setTokenProperty
}) => {
  const {
    name,
    paidAmount,
    tokens,
    tokenPurchasePrice,
    selected,
    tokenIssueDate,
    refundEligibilityWindow
  } = token
  const certificateToken = tokens?.[0]
  const {
    tokenIssueDate: certificateIssueDate,
    refundEligibilityWindow: certificateEligibilityWindow
  } = certificateToken || {}
  const refundExpiryDate = certificateToken
    ? getRefundExpiryDate(certificateIssueDate, certificateEligibilityWindow)
    : getRefundExpiryDate(tokenIssueDate, refundEligibilityWindow)
  const formattedDate = refundExpiryDate &&
    getDateStringWithYear(refundExpiryDate.toISOString())

  const setCount = event => {
    setTokenProperty({ index, property: 'selected', value: event.target.checked })
  }

  return (
    <CheckBox
      data-testid='check-token'
      onChange={setCount}
    >
      <div className='label'>
        <p>{name || 'Token'}</p>
        <p>Refund amount: ${name ? paidAmount : tokenPurchasePrice}</p>
        <p className='refund-expiry'>Eligible until {formattedDate}</p>
      </div>
      <input type='checkbox' defaultChecked={!!selected} />
      <span />
    </CheckBox>
  )
}

const SelectTokens = ({
  showMissingTokensLink,
  refundableTokens,
  setRefundableTokens,
  setIsConfirmRefund
}) => {
  const tokens = cloneDeep(refundableTokens)
  const getSelectedTokensCount = () => {
    return tokens.filter(token => !!token.selected).length
  }
  const [selectedTokensCount, setSelectedTokensCount] =
    useState(getSelectedTokensCount())

  const setTokenProperty = ({ index, property, value }) => {
    tokens[index][property] = value

    setRefundableTokens(tokens)
    setSelectedTokensCount(getSelectedTokensCount())
  }

  return (
    <>
      <Instruction marginBottom='32px' fontWeight='300'>
        Select the purchases you'd like to return.{' '}
      </Instruction>
      {refundableTokens.map((token, i) => {
        const { id } = token
        return (
          <TokenListItem
            key={id}
            index={i}
            token={token}
            setTokenProperty={setTokenProperty}
          />
        )
      })}
      {showMissingTokensLink && <MissingTokensInfoLink marginTop='16px' />}
      <Separator margin='32px 0' />
      <p>
        {utils.pluralizeWord(selectedTokensCount, 'purchase')} selected
      </p>
      <Button
        data-testid='btn-next'
        onClick={() => setIsConfirmRefund(true)}
        disabled={selectedTokensCount <= 0}
      >
        next
      </Button>
    </>
  )
}

SelectTokens.displayName = 'SelectTokens'
export default SelectTokens
