import React, { useState } from 'react'
import { MissingTokensLink } from '../style'
import NonRefundableModal from './NonRefundableModal'

const MissingTokensInfoLink = ({ marginTop }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MissingTokensLink
        marginTop={marginTop}
        data-testid='link-missing-tokens'
        onClick={() => {
          setShowModal(true)
        }}
      >
        Where are my other purchases?
      </MissingTokensLink>
      {showModal && (
        <NonRefundableModal
          show={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  )
}

MissingTokensInfoLink.displayName = 'MissingTokensInfoLink'
export default MissingTokensInfoLink
