import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ACCOUNT_PATH, CONTENT } from '../../../Constants/accountArea'
import {
  RefundContainer,
  BackToLink,
  Header,
  Instruction
} from '../style'
import { Separator } from '../../OnboardingForm/style'
import { getRefundableTokens } from '../../../utilities/tokenUtils'
import MissingTokensInfoLink from './MissingTokensInfoLink'
import SelectTokens from './SelectTokens'
import ConfirmTokens from './ConfirmTokens'
import api from '../../../api'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import BackIcon from '../../../assets/icons/back-arrow-caret.svg'

const RefundTokens = ({ setShowSnackbar }) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tokens, setTokens] = useState([])
  const [refundableTokens, setRefundableTokens] = useState([])
  const [isConfirmRefund, setIsConfirmRefund] = useState(false)

  useEffect(() => {
    const getTokens = async () => {
      const studentTokens = await api.getAllTokens({ activityLog: false })
      setIsLoading(false)

      if (!Array.isArray(studentTokens)) {
        const { message } = studentTokens || {}
        console.error('Error when fetching tokens: ', message)
        setTokens([])
        return
      }

      setRefundableTokens(getRefundableTokens(studentTokens))
      setTokens(studentTokens)
    }

    getTokens()

    // eslint-disable-next-line
  }, [])

  if (isLoading) return <LoadingSpinner />

  const showMissingTokensLink = tokens?.length !== refundableTokens?.length

  return (
    <RefundContainer>
      <BackToLink
        data-testid='back-to-account'
        onClick={() => {
          if (!isConfirmRefund) {
            history.push(`/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.TOKENS_PATH}`)
            return
          }

          setIsConfirmRefund(false)
        }}
      >
        <img alt='back-button' src={BackIcon} />
        <span>{isConfirmRefund ? 'back' : 'tokens'}</span>
      </BackToLink>
      <div>
        <div className='refund-container'>
          <Header>Request a refund</Header>
          <Separator margin='32px 0' />
          {refundableTokens?.length
            ? isConfirmRefund
              ? (
                <ConfirmTokens
                  refundableTokens={refundableTokens}
                  setShowSnackbar={setShowSnackbar}
                />
              ) : (
                (
                  <SelectTokens
                    showMissingTokensLink={showMissingTokensLink}
                    refundableTokens={refundableTokens}
                    setRefundableTokens={setRefundableTokens}
                    setIsConfirmRefund={setIsConfirmRefund}
                  />
                )
              )
            : (
              <>
                <Instruction fontWeight='300' marginBottom='16px'>
                  You don't have any purchases that are eligible for a refund.
                </Instruction>
                {showMissingTokensLink && <MissingTokensInfoLink />}
              </>
            )}
        </div>
      </div>
    </RefundContainer>
  )
}

RefundTokens.displayName = 'RefundTokens'
export default RefundTokens
